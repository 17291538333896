import { FC, ReactNode, useContext } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Flex, Text, Spinner } from '~components';
import { UserContext } from '~context';

const Wrapper = styled(Flex).attrs({
  flexDirection: { default: 'column', sm: 'row-reverse' },
  height: '100vh',
  width: '100vw',
})`
  .min-sm {
    display: none;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.sm}) {
    .max-sm {
      display: none;
    }

    .min-sm {
      display: block;
    }
  }
`;

const Title = (props) => (
  <Link href="/" passHref>
    <a>
      <Text
        as="h2"
        fontWeight="bold"
        fontSize={5}
        color="white"
        textAlign={{ default: 'center', sm: 'left' }}
        className="max-sm"
        {...props}
      >
        eyehelp
      </Text>
    </a>
  </Link>
);

interface ILayout {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  children: ReactNode;
}

const Layout: FC<ILayout> = ({ title, subtitle, children }) => {
  const { isLoading } = useContext(UserContext);

  return (
    <Wrapper>
      <Flex
        bg="professional.B900"
        flexDirection="column"
        justifyContent="space-between"
        p={6}
        pb={18}
        width={{ default: 1, md: 1 / 3 }}
        flex={1}
      >
        <Title />
        <Text
          as="h1"
          fontWeight={300}
          fontSize={6}
          className="max-sm"
          color="white"
        >
          {title}
        </Text>
      </Flex>

      <Flex
        p={{ default: 5, sm: 6 }}
        pl={{ default: 5, md: '12.5%' }}
        flexDirection="column"
        width={{ default: 1, md: 2 / 3 }}
        flex={{ default: 1, sm: 2 }}
      >
        <Title color="professional.B900" className="min-sm" />
        {isLoading ? (
          <Spinner height="100%" />
        ) : (
          <>
            <Flex
              justifyContent={{ default: 'flex-start', sm: 'center' }}
              flexBasis="100%"
              flexDirection="column"
              overflowY="auto"
            >
              <Text
                as="h1"
                fontWeight="bold"
                fontSize={7}
                mb={subtitle ? 32 : 64}
                className="min-sm"
                color="professional.B900"
              >
                {title}
              </Text>
              {subtitle && (
                <Text
                  as="p"
                  lineHeight="1.5"
                  color="professional.N900"
                  display="block"
                  maxWidth={400}
                  fontSize={3}
                  mb={32}
                >
                  {subtitle}
                </Text>
              )}
              {children}
            </Flex>
          </>
        )}
      </Flex>
    </Wrapper>
  );
};
export default Layout;
