import { get } from 'lodash';
import * as Yup from 'yup';

export const getFormValues = (initialValues) => ({
  email: get(initialValues, 'email', ''),
  password: get(initialValues, 'password', ''),
});

export const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Dit veld is verplicht'),
  password: Yup.string().min(4).required('Dit veld is verplicht'),
});
