import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';

import { Button, TextInput, Flex, Box } from '~components';
import { useAccount } from '~hooks';
import { Layout, BottomLink } from '~components/login';
import { getFormValues, validationSchema } from '~form/login';
import { handlePromise } from '~lib/helpers';

const Login = () => {
  const {
    handleSubmit,
    register,
    formState: { isDirty, errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...getFormValues({}),
    },
    resolver: yupResolver(validationSchema),
  });
  const router = useRouter();
  useAccount();

  const handleLogin = async ({ email, password }) => {
    const [err, result] = await handlePromise(
      axios.post(`/auth`, { email, password }),
      {
        errorMsg: 'Invalid e-mail or password',
      },
    );

    if (err || !(result?.id || result?.account.id)) {
      return;
    }

    if (result?.account) {
      if (!result?.account.phone) {
        router.push('/onboarding/two-factor');
        return;
      }

      if (!result?.account.verifiedPhone) {
        await handlePromise(
          axios.put(`/account/verify/phone`, { phone: result?.account.phone }),
        );
        router.push('/two-factor');
        return;
      }
    }

    if (result?.id) {
      router.push(`/two-factor?id=${result?.id}`);
      return;
    }
  };

  return (
    <>
      <NextSeo title="Login" description="Eyehelp login pagina" />
      <Layout title="Log in bij Eyehelp">
        <Flex
          as="form"
          onSubmit={handleSubmit(handleLogin)}
          maxWidth={{ default: '100%', sm: 380 }}
          flexDirection="column"
          mb={16}
        >
          <TextInput
            label="E-mail"
            name="email"
            type="email"
            error={errors.email?.message}
            mb={32}
            autoFocus
            {...register('email')}
          />
          <Box position="relative">
            <TextInput
              label="Wachtwoord"
              name="password"
              type="password"
              error={errors.password?.message}
              mb={32}
              ref={register}
              {...register('password')}
            />

            <Flex position="absolute" top="0" right="0">
              <BottomLink
                linkText="Wachtwoord vergeten?"
                href="/forgot-password"
              />
            </Flex>
          </Box>
          <Button
            width="100%"
            bg="professional.B600"
            type="submit"
            isLoading={isSubmitting}
            disabled={!isDirty}
          >
            Inloggen
          </Button>
        </Flex>

        <BottomLink
          text="Nog geen account?"
          linkText="Registreer"
          href="/register"
        />
      </Layout>
    </>
  );
};

export default Login;
