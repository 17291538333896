import { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Text, Flex, Box } from '~components';

const StyledLink = styled(Box).attrs({ as: 'a' })`
  font-size: ${(p) => p.theme.fontSizes[1]};
  font-weight: bold;
  color: ${(p) => p.theme.getRoleColor('N900')};
  text-decoration: underline;
`;

interface Props {
  linkText: string;
  href: string;
  text?: string;
  backTo?: string;
}

const BottomLink: FC<Props> = ({ linkText, href, text, backTo, children }) => {
  return (
    <Flex
      maxWidth={{ default: '100%', sm: 384 }}
      justifyContent="space-between"
      flexDirection={{ default: 'column', sm: 'row' }}
      alignItems={{ sm: 'center' }}
    >
      {backTo && (
        <Flex
          mb={{ default: 4, sm: 0 }}
          width={{ default: 1, sm: 'auto' }}
          justifyContent={{ default: 'center', sm: 'flex-start' }}
        >
          <Link href={backTo} passHref>
            <StyledLink fontWeight="normal">Terug</StyledLink>
          </Link>
        </Flex>
      )}

      {children && (
        <Flex
          mb={{ default: 4, sm: 0 }}
          width={{ default: 1, sm: 'auto' }}
          justifyContent={{ default: 'center', sm: 'flex-start' }}
        >
          {children}
        </Flex>
      )}

      <Text
        as="span"
        fontSize={1}
        textAlign={{ default: 'center', sm: 'left' }}
        color="professinal.N900"
        mt={{ default: 'auto', sm: '0' }}
      >
        {text && `${text} `}
        <Link href={href} passHref>
          <StyledLink>{linkText}</StyledLink>
        </Link>
      </Text>
    </Flex>
  );
};

export default BottomLink;
